ion-content {
    --background: #f0f0f0;
}

ion-footer {
    ion-title {
        width: auto;
    }
}

.ion-invalid {
    --border-color: var(--ion-color-danger);

    ion-label:after {
        content: ' *';
        color: var(--ion-color-danger);
    }
}

.ion-valid {

    &.ion-touched,
    &.ion-dirty {
        --border-color: var(--ion-color-success);
    }
}

ion-toolbar.ion-color-light {
    --ion-color-base: #FFFFFF !important;
    --border-width: 0 !important
}

.text-center {
    text-align: center !important;
}

a.no-decoration {
    text-decoration: none;
}

.d-block {
    display: block;
}

.h-100 {
    height: 100%;
}

.hidden {
    display: none !important;
}

/** Animated backgorund */
@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }

    100% {
        background-position: 468px 0
    }
}

.animated-background {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #FFF;
    background: linear-gradient(to right, var(--animation-background, transparent) 8%, #e0e0e0 18%, var(--animation-background, transparent) 33%);
    background-size: 800px 104px;
    position: relative;
}

.container {
    min-height: 100%;
}

.custom-input-alert {
    .alert-input {
        font-size: 1.1rem;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .alert-message {
        padding-bottom: 0;
        font-weight: bold;
        font-size: 1.1rem;
        color: var(--ion-color-primary);
    }
}

/**
segment button */
ion-segment {
    border-radius: 20px;

    ion-segment-button {
        min-width: 30px
    }
}

ion-segment-button::part(indicator-background) {
    background: var(--ion-color-primary);
}

/* Material Design styles */
ion-segment-button.md::part(native) {
    color: #000;
}

.segment-button-checked.md::part(native) {
    color: #08a391;
}

ion-segment-button.md::part(indicator-background) {
    height: 4px;
}

/* iOS styles */
ion-segment-button.ios::part(native) {
    color: #08a391;
}

.segment-button-checked.ios::part(native) {
    color: #fff;
}

ion-segment-button.ios::part(indicator-background) {
    border-radius: 20px;
}

.container {
    padding: 20px 0;
}

ion-header {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3)
}


/* desktop design */
.container {
    max-width: 800px;
    margin: auto;
}

@media (min-width: 768px) {
    ion-row {
        padding: 0 10px;

        ion-col {
            ion-card {
                margin: 0;
                min-height: 100%;
            }
        }
    }
}

@media (max-width: 767px) {
    ion-row {
        ion-col {
            ion-card {
                margin: 0 11px;
            }
        }
    }
}

p > ion-icon {
    margin-bottom: -2px;
}

.invisible {
    visibility: hidden;
}
.ion-background-white {
    background-color: #FFF;
}

ion-modal ion-content {
    --background: #FFF
}

.item-background-transparent {
    --background: transparent;
}