
  .ion-color-goldenrod {
    --ion-color-base: #DAA520;
    --ion-color-contrast: #FFF;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-contrast-rgb: 0, 0, 0;
    --ion-color-shade: #C69B1A;
    --ion-color-tint: #E0B933;
  }

  .ion-color-orchid {
    --ion-color-base: #DA70D6;
    --ion-color-contrast: #FFF;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-contrast-rgb: 0, 0, 0;
    --ion-color-shade: #C35DC2;
    --ion-color-tint: #DC7CE1;
  }
  
  .ion-color-turquoise {
    --ion-color-base: #00B0B0; 
    --ion-color-base-rgb: 0, 176, 176; 
    --ion-color-contrast: #FFF;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #009090; 
    --ion-color-tint: #00C2C2; 
  }

  .ion-color-maroon {
    --ion-color-base: #800000;
    --ion-color-base-rgb: 128, 0, 0;
    --ion-color-contrast: #FFFFFF;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #700000;
    --ion-color-tint: #910000;
  }

  .ion-color-olive {
    --ion-color-base: #808000;
    --ion-color-base-rgb: 128, 128, 0;
    --ion-color-contrast: #FFF;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #737300;
    --ion-color-tint: #8B8B00;
  }

  .ion-color-teal {
    --ion-color-base: #008080;
    --ion-color-base-rgb: 0, 128, 128;
    --ion-color-contrast: #FFFFFF;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #007171;
    --ion-color-tint: #009292;
  }

  .ion-color-indigo {
    --ion-color-base: #4B0082;
    --ion-color-base-rgb: 75, 0, 130;
    --ion-color-contrast: #FFFFFF;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #430072;
    --ion-color-tint: #530093;
  }

  .ion-color-chocolate {
    --ion-color-base: #D2691E;
    --ion-color-base-rgb: 210, 105, 30;
    --ion-color-contrast: #FFFFFF;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #B7591B;
    --ion-color-tint: #D97F2A;
  }

  .ion-color-steel-blue {
    --ion-color-base: #4682B4;
    --ion-color-base-rgb: 70, 130, 180;
    --ion-color-contrast: #FFFFFF;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #3E71A2;
    --ion-color-tint: #5397C5;
  }